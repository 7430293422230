<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h3 style="text-align: center">
                  Enter your email to receive reset link
                </h3>

                <CInput
                  v-model="form.email"
                  placeholder="Email Address"
                  type="text"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CRow>
                  <CCol class="text-left">
                    <CButton @click="submit_reset" color="info" block
                      >Send reset link</CButton
                    >
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" to="/a/login" class="ml-4 px-0"
                      >Login</CButton
                    >
                    <!-- <CButton color="link" to="/a/register" class="ml-4 px-0"
                      >Register</CButton
                    > -->
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      form: {
        email: "",
      },
      errors: [],
    };
  },
  methods: {
    submit_reset() {
      const axios = require("axios");
      console.log(this.form);
      let vm = this;
      vm.loading = true;
      axios
        .post("/forgot", this.form)
        .then((res) => {
          console.log(res);
          // localStorage.setItem("user", JSON.stringify(res.data.user));
          // localStorage.setItem("token", res.data.token);
          vm.loading = false;
          // this.$router.push("/a/reset/" + res.data.reset_token);
          this.$router.push("/a/check/email");
        })
        .catch((err) => {
          console.log(err);
          vm.loading = false;
          console.log("loading: ", vm.loading);
          vm.errors = err.response.data.errors;
          console.log(vm.errors);
          // Swal.fire("Oops...", err.response.data.message, "error");
          this.$router.push("/a/check/email");
        });
    },
  },
};
</script>
